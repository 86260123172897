'use strict';

angular.module('enervexSalesappApp').factory('JobStatus', function ($resource) {
	return $resource('/api/jobstatuses/:id', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		}
	});
});
